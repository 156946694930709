import React, { Component } from 'react';

import Field from './../../components/Field/Field';
import Button from './../../components/Button/Button';
import Card from './../../components/Card/Card';

import './SectionLogin.scss';

class SectionLogin extends Component {

  render () {

    return (
      <div className="SectionLogin">
        <Card title="Master server">
          <Field
            label="Login"
            type="text"
            placeholder="Login"
            name="name"
            value={this.props.name}
            onChange={this.props.onChange}
          />
          <Field
            label="Password"
            type="password"
            placeholder="Password"
            name="password"
            value={this.props.password}
            onChange={this.props.onChange}
            />
          <Button onClick={this.props.onSubmit} >
            Log in
          </Button>
        </Card>
      </div>
    );
  }
}

export default SectionLogin;