import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import SubmenuItem from './SubmenuItem';

class MenuItem extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isOpenedSubmenu: false
    }

    this.toggleItem = this.toggleItem.bind(this);
  }

  toggleItem () {
    this.setState((state) => {
      state.isOpenedSubmenu = !state.isOpenedSubmenu;
      return state;
    })
  }


  render () {
    
    let { name, link, path, hasSubmenu} = this.props.menuItem;

    return (
      <>
        <NavLink 
          to={link} 
          className={"MenuItem" + (this.state.isOpenedSubmenu ? " displayed" : "")}
          activeClassName="selected"
          onClick={this.toggleItem}
          isActive={(match, location) => {
            if (location.pathname.indexOf(this.props.menuItem.path) >= 0){
              return true;
            } 
          }}
        >
          <span className="MenuItem__name">
            {name}
          </span>
          {(hasSubmenu ? <img src={"/images/" + hasSubmenu} className="Sidebar__arrow" alt="" /> : null)}
        </NavLink>
        {
          (hasSubmenu ? 
            <div className="Submenu">
              {
                this.props.menuItem.submenuItems.length ?
                this.props.menuItem.submenuItems.map((submenuItem, k) => {
                  return ( 
                    <SubmenuItem submenuItem={submenuItem} key={k} />
                  )
                })
                : null
              }
            </div> 
          : 
          null)
        }
      </> 
    );
  }
}

export default MenuItem;