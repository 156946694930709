import React, { Component } from 'react';
import SidebarItem from './SidebarItem';
import './Sidebar.scss';

class Sidebar extends Component {

  constructor (props) {
    super(props);

    this.state = {
      sidebarItems: [
        {
          "img":      "home.svg",
          "name":     "Home",
          "link":     "/account/home",
          "path":     "/account/home",
        },
        {
          "img":      "statistics.svg",
          "name":     "Statistics",
          "link":     "#",
          "path":     "/account/statistics/",
          "hasMenu": "arrow-down.svg",
          "menuItems": [
            {
              "name":     "Live calls",
              "link":     "/account/statistics/live_calls",
              "path":     "/account/statistics/live_calls",
            },
            {
              "name":     "Historical",
              "link":     "/account/statistics/historical",
              "path":     "/account/statistics/historical",
            },
            {
              "name":     "Sip log",
              "link":     "/account/statistics/sip_log",
              "path":     "/account/statistics/sip_log",
            },
            {
              "name":     "CDR",
              "link":     "/account/statistics/cdr",
              "path":     "/account/statistics/cdr",
            },
            {
              "name":     "PBR",
              "link":     "/account/statistics/pbr",
              "path":     "/account/statistics/pbr",
            },
            {
              "name":     "Network finder ",
              "link":     "/account/statistics/network_finder",
              "path":     "/account/statistics/network_finder",
            }
          ]
        },
        {
          "img":      "number.svg",
          "name":     "Numbering",
          "link":     "#",
          "path":     "/account/numbering",
          "hasMenu": "arrow-down.svg",
          "menuItems": [
            {
              "name":     "Add numbers",
              "link":     "/account/numbering/add",
              "path":     "/account/numbering/add",
            },
            {
              "name":     "Remove numbers",
              "link":     "/account/numbering/remove",
              "path":     "/account/numbering/remove",
            },
            {
              "name":     "Rates",
              "link":     "/account/numbering/rates",
              "path":     "/account/numbering/rates",
            },
            {
              "name":     "Edit",
              "link":     "/account/numbering/edit",
              "path":     "/account/numbering/edit",
            },
          ]
        },
        {
          "img":      "crm.svg",
          "name":     "CRM",
          "link":     "#",
          "path":     "/account/crm",
          "hasMenu": "arrow-down.svg",
          "menuItems": [
            {
              "name":     "Suppliers",
              "link":     "/account/crm/suppliers",
              "path":     "/account/crm/suppliers",
            },
            {
              "name":     "Supplier IP",
              "link":     "/account/crm/supplier_ip",
              "path":     "/account/crm/supplier_ip",
            },
            {
              "name":     "Clients",
              "link":     "/account/crm/clients",
              "path":     "/account/crm/clients",
            },
            {
              "name":     "Users",
              "link":     "/account/crm/users",
              "path":     "/account/crm/users",
            },
          ]
        }, 
        {
          "img":      "financial.svg",
          "name":     "Financial",
          "link":     "#",
          "path":     "/account/financial",
          "hasMenu": "arrow-down.svg",
          "menuItems": [
            {
              "name":     "Supplier rates",
              "link":     "#",
              "path":     "/account/financial/supplier_rates",
              "hasSubmenu": "arrow-down.svg",
              "submenuItems": [
                {
                  "name":     "Initial upload",
                  "link":     "/account/financial/supplier_rates/initial_upload",
                  "path":     "/account/financial/supplier_rates/initial_upload",
                },
                {
                  "name":     "Edit",
                  "link":     "/account/financial/supplier_rates/edit",
                  "path":     "/account/financial/supplier_rates/edit",
                },
                {
                  "name":     "Rebuild",
                  "link":     "/account/financial/supplier_rates/rebuild",
                  "path":     "/account/financial/supplier_rates/rebuild",
                },
                {
                  "name":     "Outbound",
                  "link":     "/account/financial/supplier_rates/outbound",
                  "path":     "/account/financial/supplier_rates/outbound",
                },
              ]
            },
            {
              "name":     "Invoice & Statements",
              "link":     "#",
              "path":     "/account/financial/invoice_statements",
              "hasSubmenu": "arrow-down.svg",
              "submenuItems": [
                {
                  "name":     "Statement",
                  "link":     "/account/financial/invoice_statements/statement",
                  "path":     "/account/financial/invoice_statements/statement",
                },
                { 
                  "name":     "Invoice",
                  "link":     "/account/financial/invoice_statements/invoice",
                  "path":     "/account/financial/invoice_statements/invoice",
                },
                {
                  "name":     "Credit Note",
                  "link":     "/account/financial/invoice_statements/credit_note",
                  "path":     "/account/financial/invoice_statements/credit_note",
                },
              ]
            },
            {
              "name":     "Perfomance",
              "link":     "#",
              "path":     "/account/financial/perfomance",
              "hasSubmenu": "arrow-down.svg",
              "submenuItems": [
                {
                  "name":     "Disputes",
                  "link":     "/account/financial/perfomance/disputes",
                  "path":     "/account/financial/perfomance/disputes",
                },
                { 
                  "name":     "Suppliers",
                  "link":     "/account/financial/perfomance/suppliers",
                  "path":     "/account/financial/perfomance/suppliers",
                },
                {
                  "name":     "Staff",
                  "link":     "/account/financial/perfomance/staff",
                  "path":     "/account/financial/perfomance/staff",
                },
                {
                  "name":     "Clients",
                  "link":     "/account/financial/perfomance/clients",
                  "path":     "/account/financial/perfomance/clients",
                },
              ]
            },
            {
              "name":     "Billing terms",
              "link":     "/account/financial/billing_terms",
              "path":     "/account/financial/billing_terms",
            },
          ]
        },
        {
          "img":      "convertor.svg",
          "name":     "Currency convertor",
          "link":     "/account/convertor",
          "path":     "/account/convertor",
        },
      ],

      isOpened: false
    }
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar () {
    this.setState((state) => {
      state.isOpened = !state.isOpened;
      return state;
    })
  }

  render () {
    return (
      <div className={"Sidebar__wrapper" + (this.state.isOpened ? " isOpened" : "")}>
        <div className="Sidebar__toggler" onClick={this.toggleSidebar}>
          <img src="/images/menu.svg" alt="" />
        </div>
        <div className="Sidebar">
          {
            this.state.sidebarItems.length ?
            this.state.sidebarItems.map((sidebarItem, k) => {
              return (
                <SidebarItem sidebarItem={sidebarItem} key={k} />
              )
            })
            : null
          }
        </div>
      </div>
    );
  }
}

export default Sidebar;