import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class SubmenuItem extends Component {

  render () {
    
    let { name, link} = this.props.submenuItem; 

    return (
      <>
        <NavLink to={link} className="SubmenuItem" activeClassName="selected">
          <span className="SubmenuItem__name">
            {name}
          </span>
        </NavLink>
      </> 
    );
  }
}

export default SubmenuItem;