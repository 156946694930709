import React, { Component } from 'react';
import './CardItem.scss';

class CardItem extends Component {

  render () {

    let { name, data} = this.props.cardItem;

    return (
      <div className="CardItem">
        <div className="CardItem__name">
          {(name ? (name + (":")) : null)}
        </div>
        <div className="CardItem__data">
          { data }
        </div>
      </div>
    );
  }
}

export default CardItem;
