import React, { Component } from 'react';
import axios from 'axios';
import './Breadcrumbs.scss';

import withBreadcrumbs from "react-router-breadcrumbs-hoc";

class Breadcrumbs extends Component {

  render () {
    return (
      <div className="Container">
        <div className="Breadcrumbs">
          {this.props.breadcrumbs.map(({ breadcrumb, match }, index) => (
            <div className="BreadcrumbsItem" key={match.url}>
              {index > 1 ? breadcrumb  : null}
            </div>
          ))}
        </div>
      </div>
      );
    }
  }
  
  export default withBreadcrumbs()(Breadcrumbs);