import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import MenuItem from './MenuItem';

class SidebarItem extends Component {

    constructor (props) {
      super(props);
  
      this.state = {
        isOpenedMenu: false
      }
  
      this.toggleItem = this.toggleItem.bind(this);
    }
  
    toggleItem () {
      this.setState((state) => {
        state.isOpenedMenu = !state.isOpenedMenu;
        return state;
      })
    }

  render () {

    let { img, name, link, path, hasMenu} = this.props.sidebarItem;

    return (
      <>
        <NavLink 
          to={link}
          className={"SidebarItem" + (this.state.isOpenedMenu ? " displayed" : "")}
          activeClassName="selected"
          onClick={this.toggleItem}
          
          isActive={(match, location) => {
            if (location.pathname.indexOf(this.props.sidebarItem.path) >= 0){
              return true;
            } 
          }}
        >
          <img src={"/images/" + img} alt="" />
          <span className="SidebarItem__name hidden">
            {name}
          </span>
          {(hasMenu ? <img src={"/images/" + hasMenu} className="Sidebar__arrow" alt="" /> : null)}
        </NavLink>
        {
          (hasMenu ? 
            <div className="Menu">
              {
                this.props.sidebarItem.menuItems.length ?
                this.props.sidebarItem.menuItems.map((menuItem, k) => {
                  return ( 
                    <MenuItem menuItem={menuItem} key={k} />
                  )
                })
                : null
              }
            </div> 
          : 
          null)
        }
      </> 
    );
  }
}

export default SidebarItem;