import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment-timezone';

import './Header.scss';

class Header extends Component {

  constructor (props) {
    super(props);

    this.state = {
      datetime: null,
      live_calls: null,
      today: {
          calls: null,
          minutes: null
      }
    }
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: 'https://master.mobileyes.uk/api/server/status',
      withCredentials: true
    })
    .then((response) => {

      // Error
      if (response.data.status === 'error') {
        alert('Error:\n' + response.data.error);
        console.log('Error:\n' + response.data.error);
        return false;
      }

      // Success
      this.setState(state => {

        state = response.data.data

        return state;
      });
    })
    .catch(function (error) {
      alert('Error:\n' + error);
      console.log('Error:\n' + error);
    });
  }
  
  render () {
    return (
      <div className="Header">
        <div className="Container">
          <div className="Header__left-col">
            <div className="HeaderInfo__wrapper">
              <div className="HeaderInfo">
                Live calls: {this.state.live_calls}
              </div>
              <div className="HeaderInfo">
                CDR time: <Moment date={this.state.datetime} format="HH:mm" utc={true} />
              </div>
              <div className="HeaderInfo">
                Minutes: {this.state.today.minutes}
              </div>
              <div className="HeaderInfo">
                Server time: <Moment date={this.state.datetime} format="HH:mm" utc={true}/>
              </div>
            </div>
            <div className="HeaderDate">
              <Moment date={this.state.datetime} format="MMMM DD, YYYY" />
            </div>
          </div>
          <div className="Header__right-col">
            <div className="HeaderUser">
              {this.props.user.longname}
            </div>
            <div
              className="LogOut"
              onClick={this.props.logout}
            >
              <img src="/images/log-out.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header; 
