import React, { Component } from 'react';
import axios from 'axios';

import Card     from './../../components/Card/Card';
import CardItem from './../../components/Card/CardItem';

import './SectionHome.scss';

class SectionHome extends Component {

  constructor (props) {
    super(props);

    this.state = {
      cardLiveCallsItems: [
        {
          "data":     null,
        },
      ],
      cardStatisticsItems: [
        {
          "name":     "Mins",
          "data":     null,
        },
        {
          "name":     "Calls",
          "data":     null,
        },
        {
          "name":     "ACD",
          "data":     null,
        },
      ]
    }
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: 'https://master.mobileyes.uk/api/server/status',
      withCredentials: true
    })
    .then((response) => {

      // Error
      if (response.data.status === 'error') {
        alert('Error:\n' + response.data.error);
        console.log('Error:\n' + response.data.error);
        return false;
      }

      // Success
      this.setState(state => {

        state = response.data.data

        state = {
          cardLiveCallsItems: [
            {
              "data":     response.data.data.live_calls,
            },
          ],
          cardStatisticsItems: [
            {
              "name":     "Mins",
              "data":     response.data.data.today.minutes,
            },
            {
              "name":     "Calls",
              "data":     response.data.data.today.calls,
            },
            {
              "name":     "ACD",
              "data":     0,
            },
          ]
        }

        return state;
      });
    })
    .catch(function (error) {
      alert('Error:\n' + error);
      console.log('Error:\n' + error);
    });
  }

  render () {
    return (
      <div className="Container">
        <div className="SectionHome">
          <Card title="Live calls" classes="oneItem">
            {
              this.state.cardLiveCallsItems.length ?
              this.state.cardLiveCallsItems.map((cardItem, k) => {
                return (
                  <CardItem cardItem={cardItem} key={k} />
                )
              })
              : null
            }
          </Card>
          <Card title="Statistics">
            {
              this.state.cardStatisticsItems.length ?
              this.state.cardStatisticsItems.map((cardItem, k) => {
                return (
                  <CardItem cardItem={cardItem} key={k} />
                )
              })
              : null
            }
          </Card>
        </div>
      </div>
    );
  }
}

export default SectionHome;