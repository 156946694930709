import React, { Component } from 'react';
import './Card.scss';

class Card extends Component {

  render () {

    return (
      <div className={"Card " + (this.props.classes ? this.props.classes : null)}>
        <div className="Card__title">
          {this.props.title}
        </div>
        <div className="Card__body">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Card;
