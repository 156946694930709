import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';

import SectionLogin     from './sections/Login/SectionLogin.jsx';
import SectionHome      from './sections/Home/SectionHome.jsx';
import Sidebar          from './components/Sidebar/Sidebar.jsx';
import Header           from './components/Header/Header';
import Breadcrumbs      from './components/Breadcrumbs/Breadcrumbs.jsx';

import './App.scss';

class App extends Component {

  constructor (props) {

    super(props);

    this.state = {
      user: {
        id:         null,
        name:       null,
        longname:   null,
        group_name: null,
        isChecked:  false
      },
      login: {
        name: '',
        password: ''
      }
    }

    this.loginChange =  this.loginChange.bind(this);
    this.loginSubmit =  this.loginSubmit.bind(this);
    this.loginCheck =   this.loginCheck.bind(this);
    this.logout =       this.logout.bind(this);
  }

  componentDidMount () {
    this.loginCheck();
  }

  loginChange (event) {

    let name =  event.target.name;
    let value = event.target.value;

    this.setState(state => {
      
      state.login[name] = value

      return state;
    });
  }

  loginSubmit () {
    axios({
      method: 'post',
      url: 'https://master.mobileyes.uk/api/auth/login',
      data: this.state.login,
      withCredentials: true
    })
    .then((response) => {

      // Error
      if (response.data.status === 'error') {
        alert('Error:\n' + response.data.error);
        console.log('Error:\n' + response.data.error);
        return false;
      }

      // Success - get user data
      this.loginCheck();
      this.setState(state => {

        state.login = {
          name: '',
          password: ''
        }

        return state;
      });
    })
    .catch(function (error) {
      alert('Error:\n' + error);
      console.log('Error:\n' + error);
    });

  }

  loginCheck() {
    axios({
      method: 'get',
      url: 'https://master.mobileyes.uk/api/auth/check',
      withCredentials: true
    })
    .then((response) => {

      // Error
      // if (response.data.status === 'error') {
      //   alert('Error:\n' + response.data.error);
      //   console.log('Error:\n' + response.data.error);
      // }

      // Success
      if(response.data.data && response.data.data.id) {

        this.setState(state => {

          state.user = {
            ...response.data.data,
            isChecked: true
          }
  
          return state;
        });
      } else {
        this.setState(state => {

          state.user.isChecked = true;
  
          return state;
        });
      }
    })
    .catch(function (error) {
      alert('Error:\n' + error);
      console.log('Error:\n' + error);
    });
  }

  logout () {
    axios({
      method: 'get',
      url: 'https://master.mobileyes.uk/api/auth/logout',
      withCredentials: true
    })
    .then((response) => {

      // Error
      if (response.data.status === 'error') {
        alert('Error:\n' + response.data.error);
        console.log('Error:\n' + response.data.error);
        return false;
      }

      this.setState(state => {

        state.user = {
          id:         null,
          name:       null,
          longname:   null,
          group_name: null,
          isChecked:  true
        }

        return state;
      });
    })
    .catch(function (error) {
      alert('Error:\n' + error);
      console.log('Error:\n' + error);
    });
  }

  render () {
    return (
      <>
        <Switch>
          <Route exact path="/login">
            {
              this.state.user.isChecked &&
              this.state.user.id
              ?
                <Redirect to="/account/home" />
              :
                null
            }
            {
              this.state.user.isChecked &&
              !this.state.user.id
              ?
                <SectionLogin
                  name={this.state.login.name}
                  password={this.state.login.password}
                  onChange={this.loginChange}
                  onSubmit={this.loginSubmit}
                />
              :
                null
            }
          </Route>
          {
            this.state.user.isChecked &&
            !this.state.user.id
            ?
              <Redirect to="/login" />
            :
              null
          }
          {
            this.state.user.id
            ?
              <Route path="/account">

                <Sidebar />

                <div className="Content">

                  <Header
                    user={this.state.user}
                    logout={this.logout}
                  />
                  <Breadcrumbs />

                  {/* HOME */}
                  <Route path="/account/home">
                    <SectionHome />
                  </Route>

                  {/* STATISTICS */}
                  <Route path="/account/statistics">
                    
                    </Route>
                    <Route path="/account/statistics/live_calls">
                      
                    </Route>
                    <Route path="/account/statistics/historical">

                    </Route>
                    <Route path="/account/statistics/sip_log">
                      
                    </Route>
                    <Route path="/account/statistics/cdr">

                    </Route>
                    <Route path="/account/statistics/pbr">

                    </Route>
                    <Route path="/account/statistics/network_finder">

                  </Route>

                  {/* NUMBERING */}
                  <Route path="/account/numbering">
                      
                    </Route>
                    <Route path="/account/numbering/add">
                      
                    </Route>
                    <Route path="/account/numbering/remove">
                      
                    </Route>
                    <Route path="/account/numbering/rates">
                      
                    </Route>
                    <Route path="/account/numbering/edit">
                      
                  </Route>

                  {/* CRM */}
                  <Route path="/account/crm">
                      
                    </Route>
                    <Route path="/account/crm/suppliers">
                      
                    </Route>
                    <Route path="/account/crm/supplier_ip">
                      
                    </Route>
                    <Route path="/account/crm/clients">
                      
                    </Route>
                    <Route path="/account/crm/users">
                      
                  </Route>

                  {/* FINANCIAL */}
                  <Route path="/account/financial">
                    
                  </Route>

                  {/* FINANCIAL ==> SUPPLIER RATES*/}
                  <Route path="/account/financial/supplier_rates">
                      
                    </Route>
                    <Route path="/account/financial/supplier_rates/initial_upload">
                      
                    </Route>
                    <Route path="/account/financial/supplier_rates/edit">
                      
                    </Route>
                    <Route path="/account/financial/supplier_rates/rebuild">
                      
                    </Route>
                    <Route path="/account/financial/supplier_rates/outbound">
                      
                  </Route>

                  {/* FINANCIAL ==> SUPPLIER RATES*/}
                  <Route path="/account/financial/invoice_statements">
                      
                    </Route>
                    <Route path="/account/financial/invoice_statements/statement">
                      
                    </Route>
                    <Route path="/account/financial/invoice_statements/invoice">
                      
                    </Route>
                    <Route path="/account/financial/invoice_statements/credit_note">
                      
                  </Route>

                  {/* FINANCIAL ==> PERFOMANCE*/}
                  <Route path="/account/financial/perfomance">
                      
                    </Route>
                    <Route path="/account/financial/perfomance/disputes">
                      
                    </Route>
                    <Route path="/account/financial/perfomance/suppliers">
                      
                    </Route>
                    <Route path="/account/financial/perfomance/staff">
                      
                    </Route>
                    <Route path="/account/financial/perfomance/clients">
                      
                    </Route>

                    <Route path="/account/financial/billing_terms">
                      
                  </Route>

                  {/* CONVERTOR */}
                  <Route path="/account/convertor">
                    
                  </Route>
                </div>
              </Route>
            :
              null
          }
        </Switch>
      </>
    );
  }
}

export default App;