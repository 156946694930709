import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from                             'react';
import ReactDOM from                          'react-dom';
import {BrowserRouter as Router} from         'react-router-dom';
import { Provider } from                      'react-redux';
import { applyMiddleware, createStore } from  'redux';
import rootReducer from                       './store/rootReducer';
import logger from                            'redux-logger';

import App from './App';

const store = createStore(
  rootReducer,
  applyMiddleware(logger)
);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
);