import React, { Component } from 'react';
import './Field.scss';

class Field extends Component {

  render () {

    return (
      <label className="Field">
        <span className="Field__label">
          {this.props.label}
        </span>
        <input
          className="Field__input"
          type={this.props.type}
          placeholder={this.props.placeholder}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </label>
    );
  }
}

export default Field;
